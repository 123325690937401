<template>
    <div class="myOrderDetail">
        <div class="myOrderDetail_header">订单编号：{{orderDetail.orderNo}}</div>
        <div class="myOrderDetail_type" v-if="orderDetail.payState==0">
            <p>
                <img src="@/assets/mySelf/unpaid_icon.png" alt="">
            </p>
            <p>待支付</p>
        </div>
        <div class="myOrderDetail_type" v-if="orderDetail.payState==1">
            <p>
                <img src="@/assets/mySelf/paySuccess.png" alt="">
            </p>
            <p>已支付</p>
        </div>
        <div class="myOrderDetail_type" v-if="orderDetail.payState==2">
            <p>
                <img src="@/assets/mySelf/quxiao.png" alt="">
            </p>
            <p>已取消</p>
        </div>
        <div class="myOrderDetail_address" v-if="orderDetail.orderAddress != null">
            <p style="display: flex;
    justify-content: space-between;">收货地址 <span style="font-size:14px;cursor:pointer;color:#5F7CFD;" @click="chakanwuliu()">查看物流</span></p>
            <p style="font-size:14px;">{{orderDetail.orderAddress.name}} {{orderDetail.orderAddress.mobile}}</p>
            <p style="margin-top:10px;font-size:14px;">{{orderDetail.orderAddress.city}}{{orderDetail.orderAddress.area}}{{orderDetail.orderAddress.address}}</p>
            <!-- <p>
                <span>{{orderDetail.orderAddress.name}}</span>
                <span>{{orderDetail.orderAddress.mobile}}</span>
                <span>{{orderDetail.orderAddress.city}}{{orderDetail.orderAddress.area}}{{orderDetail.orderAddress.address}}</span>
            </p> -->
        </div>
        <div>
           
        </div>
        <div class="myOrderDetail_class" v-if="orderType ==0">
            <div class="myOrderDetail_class_title">课程</div>
            <div class="myOrderDetail_class_shopClass">
                <div class="myOrderDetail_class_shopClass_top">
                    <p class="myOrderDetail_class_shopClass_top_left">
                        <span>
                            <img :src="orderDetail.orderCourseDetail.coverPath" alt="">
                        </span>
                        <span>{{orderDetail.orderCourseDetail.coursePackageName}}</span>
                    </p>
                    <p class="myOrderDetail_class_shopClass_top_right">¥{{orderDetail.orderCourseDetail.price}}</p>
                </div>
                <!-- <div class="myOrderDetail_class_shopClass_bottom" v-if="orderDetail.orderCourseDetail.giveCourseNames.length>0">
                    <div class="myOrderDetail_class_shopClass_bottom_left">
                        <p>
                            <img src="@/assets/mySelf/donate.png" alt="">
                        </p>
                        <p>
                            <span v-for="(item,index) in orderDetail.orderCourseDetail.giveCourseNames" :key="index">{{item}}</span>
                        </p>
                    </div>
                </div> -->
            </div>
        </div>
        <div class="myOrderDetail_class" v-if="orderType ==1">
            <div class="myOrderDetail_class_title">直播</div>
            <div class="myOrderDetail_class_shopClass">
                <div class="myOrderDetail_class_shopClass_top">
                    <p class="myOrderDetail_class_shopClass_top_left">
                        <span >{{orderDetail.orderLiveDetail.name}}</span>
                        <span>{{orderDetail.orderLiveDetail.liveTime}}</span>
                    </p>
                    <p class="myOrderDetail_class_shopClass_top_right">¥{{orderDetail.orderLiveDetail.price}}</p>
                </div>
            </div>
        </div>
        <div class="myOrderDetail_orderInformation">
            <div class="myOrderDetail_orderInformation_title">订单信息</div>
            <div class="myOrderDetail_orderInformation_box">
                <div>
                    <p>总金额：</p>
                    <p>¥{{orderDetail.totalPrice}}</p>
                </div>
                <div>
                    <p>
                        <span>抵扣：</span>
                        <!-- <span>当前积分3000，可抵扣3元</span> -->
                    </p>
                    <p>¥{{orderDetail.pointDeductionPrice}}</p>
                </div>
                <div>
                    <p>优惠：</p>
                    <p>¥{{orderDetail.couponPrice}}</p>
                </div>
                <div class="myOrderDetail_orderInformation_box_total">
                    <p>共付金额：</p>
                    <p>¥{{orderDetail.actuallyPrice}}</p>
                </div>
            </div>
            
        </div>
        <div class="myOrderDetail_operate">
            <button style="background-color:#F5F5F5;color:#5F7CFD;" v-if="orderDetail.payState==0" @click="cnacelOrder()">取消订单</button>
            <button style="background-color:#5F7CFD;color:#ffffff;" v-if="orderDetail.payState==0" @click="nowPay()">立即支付</button>
        </div>
        <!-- 付钱弹窗 -->
            <div class="dialog">
                <el-dialog 
                    title="微信扫码支付"
                    :visible.sync="centerDialogVisible"
                    width="570px"
                    center>
                    <!-- <p class="dialog_between"><span>课程名称：</span><span>{{courseMap.name}}</span></p>
                    <p class="dialog_between"><span>应付金额：</span><span class="color_red">¥{{courseMap.totalPrices}}</span></p> -->
                    <p class="line"></p>
                    <p class="dialog_between" style="text-align:center;"><span>微信扫码</span></p>
                    <div id="qrcode" ref='qrcode'  class="dialog_center dialog_img" style="width: 100%;display: flex;justify-content: center;"></div>
                    <p class="dialog_center" style="text-align:center;">请使用微信扫码，支付成功后自动开通服务</p>
                    <!-- <span slot="footer" class="dialog-footer">
                        <el-button @click="tonext"> 我已完成支付 </el-button>
                    </span> -->
                </el-dialog>
            </div>

            <el-dialog
                title="物流信息"
                :visible.sync="shouhuoVisible"
                width="30%"
                :before-close="handleClose">
                 <el-timeline :reverse="reverse">
                    <el-timeline-item
                    v-for="(activity, index) in activities"
                    :key="index"
                    :timestamp="activity.ftime">
                    {{activity.context}}
                    </el-timeline-item>
                </el-timeline>
                </el-dialog>
    </div>
</template>

<script>
import { order_detail,get_logistics,order_cancel,order_pay } from "@/api/myself";
export default {
    name:'myOrderDetail',
     watch:{
        $route(to,from){
            console.log(from.path);//从哪来
            console.log(to.path);//到哪去
        },
        centerDialogVisible(oldvalue,newvalue){
            console.log(oldvalue)
            console.log(newvalue)
            if(oldvalue==false){
                this.$refs.qrcode.innerHTML = "";//清空渲染的二维码
            }
        },
        
    },
    data(){
        return{
            shouhuoVisible:false,
            reverse: true,
            activities: [],
            orderId:'',
            orderType:0,
            orderDetail:{},
            centerDialogVisible:false
        }
    },
    mounted(){
        this.orderId=this.$route.query.orderId
        this.orderType=this.$route.query.orderType
        this.order_detail()
        //this.get_logistics()
    },
    methods:{
        chakanwuliu(){
            this.get_logistics();
        },
        nowPay(){
            var obj={
                orderId:this.orderId
            }
            order_pay(obj).then(res=>{
                console.log(res)
                if(res.data.code ==0){
                    if(res.data.data.payWay=='支付宝'){
                        const div = document.createElement('div')//创建div
                        div.innerHTML = res.data.data.aliSign
                        document.body.appendChild(div)
                        console.log(document.body)
                        document.forms[0].submit();
                    }else if(res.data.data.payWay=='微信'){
                        this.centerDialogVisible=true
                        console.log(res.data.data.codeUrl)
                        // that.serviceId=res.data.data.serviceId
                        setTimeout(()=>{
                            this.$QRCodeSC(res.data.data.codeUrl)
                        },1000)
                        // this.order_status()
                        // this.order_list();
                    }else if(res.data.data.payWay == '网银'){
                        window.location.href=res.data.data.url
                    }
                }
                
            }).catch(error=>{
                console.log(error)
            })
        },
        cnacelOrder(){
            this.$confirm('确认要取消订单吗?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(() => {
                    var obj={
                        orderId:this.orderId
                    }
                    order_cancel(obj).then(res=>{
                        console.log(res)
                        if(res.data.code==0){
                            this.order_detail();
                        }
                    }).catch(error=>{
                        console.log(error)
                    })
                }).catch(() => {
            });
        },
        order_detail(){
            var obj={
                orderId:this.orderId,
                orderType:this.orderType
            }
            order_detail(obj).then(res=>{
                console.log(res)
                if(res.data.code==0){
                    this.orderDetail=res.data.data
                }else{
                    this.$message.error(res.data.msg)
                }
            }).catch(error=>{
                console.log(error)
            })
        },
        get_logistics(){
            var obj={
                orderId:this.orderId
            }
            get_logistics(obj).then(res=>{
                console.log(res)
                if(res.data.code==0){
                    this.activities=res.data.data.nodeList;
                    this.shouhuoVisible = true;
                }else{
                    this.$message.error(res.data.msg)
                }
            }).catch(error=>{
                console.log(error)
            })
        }
    }
}
</script>

<style>
.el-timeline-item__content{
    text-align: left;
}
.el-timeline-item__timestamp{
    text-align: left;
}
</style>